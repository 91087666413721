document.addEventListener("DOMContentLoaded", function () {

	// We listen every time a grid/template/content is initialized.
	window.WP_Grid_Builder && WP_Grid_Builder.on( 'init', function( wpgb ) {

		// Appliquer immédiatement après le chargement initial
		replaceFacetTranslations();

		// Appliquer après chaque mise à jour AJAX
		wpgb.facets.on('render', function (element, facet) {
			//console.log('WPGB Facet Rendered:', element, facet);
			replaceFacetTranslations();
		});

		function replaceFacetTranslations() {
			if (!window.facetTranslations) return;

			let currentLang = document.documentElement.lang.toUpperCase() || 'EN';

			if (window.facetTranslations[currentLang]) {
				document.querySelectorAll('.wpgb-facet').forEach(facet => {
					Object.keys(window.facetTranslations[currentLang]).forEach(original => {
						let translated = window.facetTranslations[currentLang][original];

						// Debugging pour voir les remplacements
						//console.log(`Remplacement : ${original} -> ${translated}`);

						// Remplacement des valeurs originales par les traductions
						facet.innerHTML = facet.innerHTML.replace(new RegExp('\\b' + original + '\\b', 'g'), translated);
					});
				});
			}
		}


		// Au chargement complet de la page, masquer le loader, afficher le contenu et scroller dessus
		window.addEventListener("load", function() {
			var loader = document.getElementById("loader");
			var content = document.getElementById("content-wpgb");
			if (loader) {
				loader.style.display = "none";
			}
			if (content) {
				content.style.display = "";
				// Scroller doucement sur le contenu
				content.scrollIntoView({ behavior: "smooth" });
			}
			console.log("Page chargée : loader caché, contenu affiché et scroll effectué");
		});

		// Lors d'un changement de facette en langue non FR, forcer un rechargement complet de la page
		if (document.documentElement.lang.toUpperCase() !== "FR") {
			wpgb.facets.on('change', function(event, facet, value) {
				console.log("Facette modifiée – affichage du loader et rechargement complet.");
				var loader = document.getElementById("loader");
				var content = document.getElementById("content-wpgb");
				if (content) {
					content.style.display = "none";
				}
				if (loader) {
					loader.style.display = "flex";
				}
				// Mettre à jour l'URL avec la sélection des facettes
				wpgb.facets.pushState();
				// Forcer un rechargement complet sans ajouter d'ancre à l'URL
				window.location.reload();
			});
		}
	} );
});